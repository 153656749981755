.chat-v1_5 {
  @import "../shared/_defaults";

  // Device colors

  $phone-light: #eeeeee;
  $camera-color-light: #dddddd;
  $button-color-light: #cccccc;

  $phone-dark: #000000;
  $camera-color-dark: #444444;
  $button-color-dark: #222222;

  // COLOR THEMES

  // Default color theme

  $default-chat-bg: $white;

  $default-incoming-bg: $brand-primary;
  $default-outgoing-bg: #dddddd;

  $default-incoming-text: $white;
  $default-outgoing-text: $dark-gray;

  $default-timestamp-color: $medium-gray;

  $default-banner-color: #f7f7f7;
  $default-banner-avatar-color: $medium-gray;
  $default-banner-initials-color: $white;
  $default-banner-title-color: $dark-gray;

  // Cupertino light

  $cupertino-light-chat-bg: #ffffff;

  $cupertino-light-incoming-bg: #1d8dfd;
  $cupertino-light-outgoing-bg: #e5e5e5;

  $cupertino-light-incoming-text: #ffffff;
  $cupertino-light-outgoing-text: #000000;

  $cupertino-light-timestamp-color: #999999;

  $cupertino-light-banner-color: #ffffff;
  $cupertino-light-banner-avatar-color: #999999;
  $cupertino-light-banner-initials-color: #ffffff;
  $cupertino-light-banner-title-color: #000000;

  // Cupertino dark

  $cupertino-dark-chat-bg: #21252b;

  $cupertino-dark-incoming-bg: #026adb;
  $cupertino-dark-outgoing-bg: #555555;

  $cupertino-dark-incoming-text: #ffffff;
  $cupertino-dark-outgoing-text: #ffffff;

  $cupertino-dark-timestamp-color: #cccccc;

  $cupertino-dark-banner-color: #21252b;
  $cupertino-dark-banner-avatar-color: #555555;
  $cupertino-dark-banner-initials-color: #ffffff;
  $cupertino-dark-banner-title-color: #ffffff;

  // Display's base font-size
  font-size: 1vh;

  .list-enter-active,
  .list-move {
    transition: 400ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }

  .list-enter {
    opacity: 0;
    transform: scale(0.55);
    transform-origin: left bottom;
  }

  .list-enter-to {
    opacity: 1;
    transform: scale(1);
    transform-origin: left bottom;

    &.outgoing {
      transform-origin: right bottom;
    }
  }

  .list-leave-active {
    position: absolute;
    opacity: 0;
  }

  .posts {
    @include simple-center-align;
    width: 47.8125em;
    height: 85em;
    background-color: $default-chat-bg;
    overflow: hidden;
  }

  .posts-wrapper {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5em;
  }

  .has-banner .posts-wrapper {
    height: calc(100% - 7.75em);
  }

  .has-network-bar .posts-wrapper {
    height: calc(100% - 9.75em);
  }

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7.75em;
    padding: 1em 1.5em;
    text-align: center;
    box-shadow: 0 .5em 1em rgba($black, .15);
    background-color: $default-banner-color;
    z-index: 1;
  }

  .has-network-bar .banner {
    height: 9.75em;
  }

  .banner-avatar {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    margin: auto;
    border-radius: 100%;
    font-size: 1.5em;
    line-height: 2.5em;
    background-color: $default-banner-avatar-color;
    overflow: hidden;
  }

  .banner-avatar-initials {
    text-transform: uppercase;
    color: $default-banner-initials-color;
  }

  .banner-avatar-image {
    @include simple-center-align;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .banner-title {
    font-size: 1.5em;
    color: $default-banner-title-color;
  }

  .title-text {
    @include ellipsis;
  }

  .network-bar {
    font-size: 1.25em;
    text-align: left;
    color: $default-banner-title-color;
  }

  .service-provider {
    margin-left: .25em;
  }

  .signal {
    float: left;
    line-height: 1.5;
  }

  .battery {
    float: right;
    font-size: 1.5em;
  }

  .device {
    @include simple-center-align;
  }

  // Generic phone
  .theme-phone_dark,
  .theme-phone_light {
    .posts {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: .25em;
    }

    .posts-wrapper {
      padding: 2em 1.5em;
    }

    .device {
      width: 42.5em;
      height: 85em;
      padding: 6em 1em;
      border-radius: 4em;
    }

    .device-top {
      position: absolute;
      top: 0;
      bottom: auto;
      left: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 30em);
      height: 6em;
    }

    .device-bottom {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 6em;
    }

    .camera {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      margin: auto;
      width: 1em;
      height: 1em;
      border-radius: 100%;
    }

    .speaker,
    .home {
      @include simple-center-align;
    }

    .speaker {
      width: calc(100% - 4em);
      height: 1em;
      border-radius: 1em;
    }

    .home {
      width: 3em;
      height: 3em;
      border-radius: .5em;
    }
  }

  .theme-phone_dark {
    .device {
      background-color: $phone-dark;
    }

    .camera {
      background-color: $camera-color-dark;
    }

    .speaker,
    .home {
      background-color: $button-color-dark;
    }
  }

  .theme-phone_light {
    .device {
      background-color: $phone-light;
    }

    .camera {
      background-color: $camera-color-light;
    }

    .speaker,
    .home {
      background-color: $button-color-light;
    }
  }

  // iPhone
  .theme-cupertino_dark,
  .theme-cupertino_light {
    .posts {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 4.5em;
    }

    .posts-wrapper {
      padding: 4em 1.5em 2em;
    }

    &.has-banner .posts-wrapper {
      height: calc(100% - 10.75em);
      padding: 1.5em 1.5em 2em;
    }

    &.has-network-bar .posts-wrapper {
      height: calc(100% - 12.75em);
    }

    .banner {
      height: 10.75em;
      padding-top: 4em;
    }

    &.has-network-bar .banner {
      height: 12.75em;
    }

    .device {
      width: 41.9735em;
      height: 85em;
      padding: 2em;
      border-radius: 6em;
    }

    .notch {
      position: absolute;
      top: 2em;
      bottom: auto;
      left: 0;
      right: 0;
      margin: auto;
      width: 50%;
      height: 3em;
      z-index: 10;
      border-bottom-left-radius: 2.25em;
      border-bottom-right-radius: 2.25em;
    }

    .notch-curve-left,
    .notch-curve-right {
      content: "";
      position: absolute;
      top: 0;
      width: .5em;
      height: .5em;
    }

    .notch-curve-left {
      right: 100%;
    }

    .notch-curve-right {
      left: 100%;
    }

    .camera {
      position: absolute;
      top: .75em;
      bottom: auto;
      left: auto;
      right: 20%;
      margin: auto;
      width: .75em;
      height: .75em;
      border-radius: 100%;
    }

    .speaker {
      position: absolute;
      top: .75em;
      bottom: auto;
      left: 0;
      right: 0;
      margin: auto;
      width: 30%;
      height: .75em;
      border-radius: .75em;
    }

    .volume,
    .up,
    .down,
    .sleep {
      position: absolute;
      width: .5em;
    }

    .sleep {
      top: 17em;
      left: 100%;
      height: 9em;
    }

    .volume {
      top: 10em;
      right: 100%;
      height: 3em;
    }

    .up,
    .down {
      left: 0;
      height: 5em;
    }

    .up {
      top: 6em;
    }

    .down {
      top: 12em;
    }
  }

  .theme-cupertino_dark {

    .device,
    .notch {
      background-color: $phone-dark;
    }

    .notch-curve-left {
      background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, $phone-dark 70%, $phone-dark 100%);
    }

    .notch-curve-right {
      background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, $phone-dark 70%, $phone-dark 100%);
    }

    .camera {
      background-color: $camera-color-dark;
    }

    .speaker,
    .volume,
    .up,
    .down,
    .sleep {
      background-color: $button-color-dark;
    }
  }

  .theme-cupertino_light {

    .device,
    .notch {
      background-color: $phone-light;
    }

    .notch-curve-left {
      background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, $phone-light 70%, $phone-light 100%);
    }

    .notch-curve-right {
      background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, $phone-light 70%, $phone-light 100%);
    }

    .camera {
      background-color: $camera-color-light;
    }

    .speaker,
    .volume,
    .up,
    .down,
    .sleep {
      background-color: $button-color-light;
    }
  }

  // Android phone
  .theme-mountain_view_dark,
  .theme-mountain_view_light {
    .posts {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 2.5em;
    }

    .posts-wrapper {
      padding: 2em 1.5em;
    }

    .device {
      width: 40.6443em;
      height: 85em;
      padding: 5em 2em;
      border-radius: 4em;
    }

    .device-top,
    .device-bottom {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 10em);
      height: 5em;
    }

    .device-top {
      top: 0;
      bottom: auto;
    }

    .device-bottom {
      top: auto;
      bottom: 0;
    }

    .camera {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      margin: auto;
      width: 1em;
      height: 1em;
      border-radius: 100%;
    }

    .speaker,
    .home {
      @include simple-center-align;
      width: 50%;
      height: 1em;
      border-radius: 1em;
    }

    .sleep,
    .volume {
      position: absolute;
      left: 100%;
      width: .5em;
    }

    .sleep {
      top: 18em;
      height: 5em;
    }

    .volume {
      top: 30em;
      height: 10em;
    }
  }

  .theme-mountain_view_dark {
    .device {
      background-color: $phone-dark;
    }

    .camera {
      background-color: $camera-color-dark;
    }

    .speaker,
    .home,
    .sleep,
    .volume {
      background-color: $button-color-dark;
    }
  }

  .theme-mountain_view_light {
    .device {
      background-color: $phone-light;
    }

    .camera {
      background-color: $camera-color-light;
    }

    .speaker,
    .home,
    .sleep,
    .volume {
      background-color: $button-color-light;
    }
  }

  .post {
    width: auto;
    max-width: 75%;
    height: auto;
    margin-top: 1em;
    font-size: 1.5em;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .incoming {
    color: $default-incoming-text;
  }

  .outgoing {
    color: $default-outgoing-text;
    align-self: flex-end;
  }

  a {
    color: inherit;
  }

  .message-wrap {
    position: relative;
    display: flex;
    align-items: center;
  }

  .outgoing .message-wrap {
    flex-direction: row-reverse;
  }

  .show-avatar {
    &.incoming .message-wrap {
      padding-left: 3em;
    }

    &.outgoing .message-wrap {
      padding-right: 3em;
    }
  }

  .message-bubble {
    position: relative;
    overflow: hidden;
  }

  .incoming .message-bubble {
    border-radius: 1em 1em 1em 0;
    background-color: $default-incoming-bg;
  }

  .outgoing .message-bubble {
    border-radius: 1em 1em 0 1em;
    background-color: $default-outgoing-bg;
  }

  .message {
    padding: .5em .75em;
  }

  .media {
    display: block;
    width: 100%;
    height: auto;
  }

  .author-bubble {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    line-height: 2.5em;
    text-align: center;
    overflow: hidden;
  }

  .incoming .author-bubble {
    left: 0;
    background-color: $default-incoming-bg;
  }

  .outgoing .author-bubble {
    right: 0;
    background-color: $default-outgoing-bg;
  }

  .avatar {
    @include simple-center-align;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .initials {
    text-transform: uppercase;
  }

  .author,
  .post-time {
    @include ellipsis;
    color: $default-timestamp-color;
  }

  .author {
    margin-bottom: .15em;
  }

  .post-time {
    margin-top: .15em;
  }

  .outgoing {

    .author,
    .post-time {
      text-align: right;
    }
  }

  .show-avatar {
    &.incoming {

      .author,
      .post-time {
        margin-left: 3em;
      }
    }

    &.outgoing {

      .author,
      .post-time {
        margin-right: 3em;
      }
    }
  }

  .username,
  .timestamp {
    font-size: .9em;
  }

  // Non-default color themes

  .color-theme-cupertino_light_ui {
    .posts {
      background-color: $cupertino-light-chat-bg;
    }

    .banner {
      background-color: $cupertino-light-banner-color;
    }

    .banner-avatar {
      background-color: $cupertino-light-banner-avatar-color;
    }

    .banner-avatar-initials {
      color: $cupertino-light-banner-initials-color;
    }

    .network-bar,
    .banner-title {
      color: $cupertino-light-banner-title-color;
    }

    .incoming {
      color: $cupertino-light-incoming-text;

      .message-bubble {
        background-color: $cupertino-light-incoming-bg;
      }

      .author-bubble {
        background-color: $cupertino-light-incoming-bg;
      }
    }

    .outgoing {
      color: $cupertino-light-outgoing-text;

      .message-bubble {
        background-color: $cupertino-light-outgoing-bg;
      }

      .author-bubble {
        background-color: $cupertino-light-outgoing-bg;
      }
    }

    .author,
    .post-time {
      color: $cupertino-light-timestamp-color;
    }
  }

  .color-theme-cupertino_dark_ui {
    .posts {
      background-color: $cupertino-dark-chat-bg;
    }

    .banner {
      background-color: $cupertino-dark-banner-color;
      box-shadow: 0 .5em 1em rgba($black, .5);
    }

    .banner-avatar {
      background-color: $cupertino-dark-banner-avatar-color;
    }

    .banner-avatar-initials {
      color: $cupertino-dark-banner-initials-color;
    }

    .network-bar,
    .banner-title {
      color: $cupertino-dark-banner-title-color;
    }

    .incoming {
      color: $cupertino-dark-incoming-text;

      .message-bubble {
        background-color: $cupertino-dark-incoming-bg;
      }

      .author-bubble {
        background-color: $cupertino-dark-incoming-bg;
      }
    }

    .outgoing {
      color: $cupertino-dark-outgoing-text;

      .message-bubble {
        background-color: $cupertino-dark-outgoing-bg;
      }

      .author-bubble {
        background-color: $cupertino-dark-outgoing-bg;
      }
    }

    .author,
    .post-time {
      color: $cupertino-dark-timestamp-color;
    }
  }
}
